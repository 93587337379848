import React, { useContext, forwardRef, ReactText, useMemo } from "react"
import { match, Route } from 'react-router-dom';
import classnames from 'classnames';
import { Form, Button, Segment, Grid } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import { PadlessSegment } from "../../../shared/Segments";
import { CustomerContext } from "../../../contexts/CustomerContext";
import { useQueryParams, StringParam } from 'use-query-params';
import { Dropdown, FormField } from "../../../shared/Fields";
import { UserContext } from "../../../contexts/UserContext";
import { Statement } from "./Statement";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { subMonths } from "date-fns";
import { FORM_ERROR } from "final-form";

export interface StatementProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

type FormState = {
  customer: { value?: string | null; label?: string | ReactText, disabled?: boolean };
  period: { value?: string | null; label?: string | ReactText, disabled?: boolean };
  startDate: { value?: string | null; label?: string | ReactText, disabled?: boolean };
  endDate: { value?: string | null; label?: string | ReactText, disabled?: boolean }
};

export type StatementInvoice = {
  id: string;
  date: Date;
  number: string;
  customerReference: string;
  amount: number;
  paid: number;
  balance: number;
  currency: string;
}

const date = new Date();
  
const latestMonth = date.getMonth() + 1;
const latestDateStr = `${date.getFullYear()}-${("0" + latestMonth).slice(-2)}`;

const periodOptions = [
  { value: "period", label: "Use selected period" },
  { value: "mtd", label: "Month to Date" },
  { value: "cytd", label: "Calendar Year to Date" },
  { value: "fytd", label: "Financial Year to Date" },
]

const PeriodLabel = (period: string) => {
  switch(period) {
    case "mtd":
      return "Month to Date"
    case "cytd":
      return "Calendar Year to Date"
    case "fytd":
      return "Financial Year to Date"
    default:
      return "Use selected period"
  }
}

export const Statements = forwardRef<HTMLDivElement, StatementProps>(({ children, className, ...props }, ref) => {
  const [filter, setFilter] = useQueryParams({
    customer: StringParam,
    period: StringParam,
    startMonth: StringParam,
    endMonth: StringParam,
  });

  const { user } = useContext(UserContext);
  const { customers, loading: customerLoading } = useContext(CustomerContext);
  const { company } = useContext(CompanyContext)

  const finyearStart = date.getMonth() + 1 > company.financialYearStart 
    ? `${date.getFullYear()}-${("0" + company.financialYearStart).slice(-2)}` 
    : `${date.getFullYear() - 1}-${("0" + company.financialYearStart).slice(-2)}`;
  const calyearStart = `${date.getFullYear()}-01`;

  const selectedCustomer = customers.find((cust: any) => cust.id == filter.customer) || null;
  const selectedPeriod = useMemo(() => filter.period
    ? { value: filter.period, label: PeriodLabel(filter.period) } 
    : { value: "mtd", label: PeriodLabel("mtd") }, 
  [filter])
  const selectedStartMonth = useMemo(() => filter.startMonth
    ? { value: filter.startMonth, label: filter.startMonth } 
    : { value: latestDateStr, label: latestDateStr }, 
  [filter])
  const selectedEndMonth = useMemo(() => filter.endMonth
    ? { value: filter.endMonth, label: filter.endMonth } 
    : { value: latestDateStr, label: latestDateStr }, 
  [filter])

  const showResult = filter.customer && filter.startMonth && filter.endMonth

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <FinalForm<Partial<FormState>>
        initialValues={{
          customer: selectedCustomer ? { value: selectedCustomer.id, label: selectedCustomer.name } : { value: null, label: 'Select a Customer' },
          period: selectedPeriod,
          startDate: selectedStartMonth,
          endDate: selectedEndMonth,
        }}
        onSubmit={(values) => {
          const cust = (values.customer as any).value || undefined
          const period = (values.period as any).value || undefined
          const startDate = (values.startDate as any).value || undefined
          const endDate = (values.endDate as any).value || undefined
          if(period == "period" && startDate > endDate) {
            return { [FORM_ERROR]: "Start date cannot be after end date" }
          }

          const startMonth = () => {
            switch(period) {
              case "mtd":
                return endDate
              case "cytd":
                return calyearStart
              case "fytd":
                return finyearStart
              default:
                return startDate
            }
          }
          setFilter({
            customer: cust,
            period: period,
            startMonth: startMonth(),
            endMonth: endDate
          });
        }}
        render={({ handleSubmit, submitting, values: v, submitError }) => {
          return(
          <Form size="large" className="report-filter-selection" onSubmit={handleSubmit} keepDirtyOnReinitialize>
            <h3>Statements</h3>
            <PadlessSegment color="green">
              <Segment>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width="7">
                      <Field
                        disabled={false}
                        name="customer"
                        label="Customer"
                        control={Dropdown}
                        validate={(value: any) => !value.value && 'You need to select a customer'}
                        options={[
                          ...customers.map((customer) => ({ label: customer.name, value: customer.id })),
                        ]}
                        onChange={() => handleSubmit()}
                        component={FormField}
                      />
                    </Grid.Column>
                    <Grid.Column width="3">
                      <Field
                        disabled={false}
                        name="period"
                        label="Period"
                        control={Dropdown}
                        options={periodOptions}
                        component={FormField}
                      />
                    </Grid.Column>
                    <Grid.Column width="3">
                      <Field
                        disabled={false}
                        name="startDate"
                        label="Start Date"
                        control={Dropdown}
                        options={[
                          ...Array(12).fill(1).map((el,i) => {
                            const d = subMonths(date, i);
                            const month = d.getMonth() + 1;
                            const dateStr = `${d.getFullYear()}-${("0" + month).slice(-2)}`
                            return ({ label: dateStr, value: dateStr })
                          }),
                        ]}
                        component={FormField}
                      />
                    </Grid.Column>
                    <Grid.Column width="3">
                      <Field
                        disabled={false}
                        name="endDate"
                        label="End Date"
                        control={Dropdown}
                        options={[
                          ...Array(12).fill(1).map((el,i) => {
                            const d = subMonths(date, i);
                            const month = d.getMonth() + 1;
                            const dateStr = `${d.getFullYear()}-${("0" + month).slice(-2)}`
                            return ({ label: dateStr, value: dateStr})
                          }),
                        ]}
                        component={FormField}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {submitError && <div style={{ paddingRight: '1em', color: '#800' }}>{submitError}</div>}
                  <Button.Group size="mini" floated="right">
                    <Button color="green" type="submit" disabled={submitting} loading={submitting} size="large">
                      Submit
                    </Button>
                  </Button.Group>
                </div>
              </Segment>
            </PadlessSegment>
          </Form>
        )}}
      />
      {showResult && <Route render={({ match }) => <Statement user={user} filter={filter} />} />}
    </div>
  )
});