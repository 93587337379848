import React, { forwardRef, useContext, HTMLAttributes, useState } from 'react';
import classnames from 'classnames';
import { Segment, Form, Grid, Input, Button, Loader, Image, Card } from 'semantic-ui-react';
import { extractValidationError } from '../../../lib/utils';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormField, EmailInput, ImageUpload } from '../../../shared/Fields';
import { profileColors } from '../shared';
import { UserContext } from '../../../contexts/UserContext';
import { LoadingWrapper } from '../../../shared/Loading';
import { User, updateUser } from '../../../lib/api/user';

export interface ProfileDetailsProps extends HTMLAttributes<HTMLDivElement> {}

export const ProfileDetails = forwardRef<HTMLDivElement, ProfileDetailsProps>(({ children, className, ...props }, ref) => {
  const { user, loading, hydrate } = useContext(UserContext);
  const [uploading, setUploading] = useState(false);

  if (!loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Fetching your user...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)} color={profileColors.profile}>
      <Grid>
            <Grid.Row>
              <Grid.Column>
                <h3>Profile details</h3>
                <FinalForm<User & { image?: File }>
                  onSubmit={async (user) => {
                    const response = await updateUser(user);
                    if (response.status == 422) {
                      const validation = await response.json();
                      return extractValidationError(validation);
                    }
                    if (response.status != 200) {
                      throw new Error('Unable to save');
                    }
                    await hydrate();
                  }}
                  initialValues={user}
                  render={({ handleSubmit, submitting, values = {}, form, dirty }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Segment color={profileColors.profile}>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={4}>
                                {uploading ? (
                                  <ImageUpload
                                    icon="user"
                                    file={(values as any).image}
                                    onDrop={(files) => form.change('image', files[0])}
                                  />
                                ) : (
                                  <Card>
                                    <Image src={`/api/identity/avatar.png?version=${user.imageVersion}`} wrapped ui={false} />
                                    <Card.Content>
                                      <Card.Header>{values.displayName}</Card.Header>
                                      <Card.Meta>
                                        <span className="date">
                                          {values.firstName} {values.lastName} - {values.email}
                                        </span>
                                      </Card.Meta>
                                    </Card.Content>
                                    <Card.Content extra>
                                      <Button size="mini" color="olive" onClick={() => setUploading(true)} type="button">
                                        Change image
                                      </Button>
                                    </Card.Content>
                                  </Card>
                                )}
                              </Grid.Column>
                              <Grid.Column width={12}>
                                <Form.Group grouped>
                                  <Field
                                    name="displayName"
                                    validate={(value) => !value && 'You must have a name'}
                                    label="Display Name"
                                    placeholder="e.g. James"
                                    control={Input}
                                    component={FormField}
                                  />
                                  <Field
                                    name="email"
                                    validate={(value) => !value && 'You must have a email'}
                                    label="Email"
                                    placeholder="e.g. bob@eaxmple.com"
                                    control={EmailInput}
                                    component={FormField}
                                  />
                                </Form.Group>
                                <Form.Group grouped>
                                  <Field
                                    name="firstName"
                                    validate={(value) => !value && 'You must have a name'}
                                    label="First Name"
                                    placeholder="e.g. James"
                                    control={Input}
                                    component={FormField}
                                  />
                                  <Field
                                    name="lastName"
                                    label="Last Name"
                                    placeholder="e.g. Robertson."
                                    control={Input}
                                    component={FormField}
                                  />
                                </Form.Group>
                                <Form.Group grouped>
                                  <Field
                                    name="contactNumber"
                                    label="Contact number"
                                    placeholder="e.g. 013 222 555"
                                    control={Input}
                                    component={FormField}
                                  />
                                </Form.Group>
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column>
                                <Button.Group>
                                  <Button type="submit" loading={submitting} color={profileColors.profile}>
                                    Save
                                  </Button>
                                  <Button disabled={!dirty} onClick={() => form.reset()}>
                                    Reset
                                  </Button>
                                </Button.Group>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                      </Form>
                    );
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
    </div>
  );
});
