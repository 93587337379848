import React, { forwardRef, useContext } from 'react';
import classnames from 'classnames';
import { Redirect } from 'react-router';
import { hydrate } from 'react-dom';
import { Form, Message, Button, Header } from 'semantic-ui-react';
import { company } from '../../lib/api/companies';
import { Field } from 'react-final-form';
import { EmailInput, PasswordInput, FormField } from '../../shared/Fields';
import { validatePassword } from '../../lib/validation';
import { Link } from 'react-router-dom';
import { Form as FinalForm } from 'react-final-form';
import { UserContext } from '../../contexts/UserContext';
import { CompanyContext } from '../../contexts/CompanyContext';
import { request } from '../../lib/api/base';
import { DelayedRedirect } from '.';
import { Segment } from '../../shared/Segments';

export interface LoginProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

export class NotFoundError extends Error {
  status = 404;
  constructor(message: string) {
    super(message);
  }
}

export const LoginForm = forwardRef<HTMLDivElement, LoginProps>(({ children, className, ...props }, ref) => {
  const { user, hydrate } = useContext(UserContext);
  const { company, loading } = useContext(CompanyContext);

  return (
    <div {...props} ref={ref} className={classnames('', {}, className)}>
      {user.id && <Redirect to="/" />}
      <FinalForm<{ email: string; password: string; displayName: string }>
        onSubmit={async (values) => {
          try {
            const response = await request('/identity/login', {
              method: 'POST',
              body: JSON.stringify(values),
            });
            const statusCode = response.status;
            const data = await response.json();
            console.log('data: ', data);
            if (statusCode === 404) {
              throw new NotFoundError(data.message);
            }
            if (statusCode !== 200) {
              throw new Error(data.message);
            }
            if (data.message) {
              return {
                status: statusCode,
                ...data,
              };
            }
            await hydrate();
          } catch (e) {
            return {
              status: e.status || 500,
              message: e.message || "We weren't able to log you in, please try again later",
            };
          }
        }}
        validate={(values) => {
          if (!values.email) {
            return {
              email: 'You need to provide us with an email address',
            };
          }
          if (!values.password) {
            return {
              password: 'You need to provide us with an password address',
            };
          }
        }}
        render={({ handleSubmit, submitting, submitErrors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div>
                {submitErrors && submitErrors.status === 404 && (
                  <Message>
                    <Message.Header>Oops! We can't seem to find your account</Message.Header>
                    <Message.Content as="p">{submitErrors.message}</Message.Content>
                    Register {` `}
                    <Link to="/sign-up">here</Link>
                  </Message>
                )}
                {submitErrors && submitErrors.status !== 200 && submitErrors.status !== 404 && (
                  <Message header="Oops! something seems to be broken" content={submitErrors.message} />
                )}
                {submitErrors && submitErrors.status === 200 ? (
                  <>
                    <Message content={`Awesome, you've been logged in`} />
                    <DelayedRedirect wait={300} to={company.complete ? '/' : '/profile/company'} />
                  </>
                ) : (
                  <>
                    <Header as="h3" color="teal" textAlign="center">
                      Log in to your account
                    </Header>
                    <Segment>
                      <Field name="email" placeholder="you@example.com" control={EmailInput} component={FormField} />
                      <Field
                        name="password"
                        placeholder="password"
                        validate={validatePassword}
                        control={PasswordInput}
                        component={FormField}
                      />
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button color="teal" type="submit" disabled={submitting} loading={submitting} size="large">
                          Log in
                        </Button>
                      </div>
                    </Segment>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      />
      <Message>
        New to us? <Link to="/sign-up">Sign Up</Link> or <Link to="/forgot-password">Forgot password</Link>
      </Message>
    </div>
  );
});
