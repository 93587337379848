import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import { addDays, differenceInCalendarDays, format, parse } from 'date-fns';
import React, { FC, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { Link, match } from 'react-router-dom';
import Select from 'react-select';
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Button, Grid, Icon, Input, Label, Loader, Menu, Statistic, Table as T, TableCell, TableRow, Tab, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { CustomerContext } from '../../contexts/CustomerContext';
import { InvoiceContext } from '../../contexts/InvoiceContext';
import { UserContext } from '../../contexts/UserContext';
import useDebounce from '../../hooks/debounce';
import { addInvoice, Invoice, invoiceColors, InvoiceItem, Statuses, updateInvoice, invoice as getInvoice } from '../../lib/api/invoices';
import { currencies } from '../../lib/currencies';
import { history } from '../../lib/history';
import { Section } from '../../shared/Header';
import { LoadingWrapper } from '../../shared/Loading';
import { Notice } from '../../shared/Notice';
import { Pager } from '../../shared/Pager';
import { PadlessSegment, Segment } from '../../shared/Segments';
import { CreateableDropdown, FormField } from '../../shared/Fields';
import { Field, Form as FinalForm, useForm, useFormState } from 'react-final-form';
import { extractValidationError } from '../../lib/utils';

export const Status: FC<{ status: Statuses }> = ({ status }) => {
  return (
    <Label style={{ textAlign: 'center', display: 'block' }} size="small" color={invoiceColors[status] || 'black'}>
      {status}
    </Label>
  );
};

const GraphGrid = styled(Grid)`
  &&&&& {
    background-color: #f8f8f8;
  }
`;

const Table = styled(T)`
  tr {
    cursor: pointer;
    &:hover {
      background-color: #f8f8f8;
    }
  }
`;

const Filters = styled(Section)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
  justify-content: space-between;
  .ui.menu {
    margin-bottom: 0;
  }
  > * + * {
    margin-left: 0.75em;
  }
`;

const StatSegment = styled(Segment)`
  &&&&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2em;
  }
`;

const TopSection = styled(Section)`
  margin-bottom: 1em;
`;

const SendConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const SendConfirmationMessage = styled.p`
  padding-right: 10px;
  color: #555;
  margin: 0 auto;
`;

const barColours = [
  { light: '#9dcb49', dark: '#B7E563' },
  { light: '#dc634f', dark: '#F67D69' },
  { light: '#f6c358', dark: '#FFDD72' },
  { light: '#21ba45', dark: '#3BD45F' },
  { light: '#3bb2dc', dark: '#55CCF6' },
  { light: '#13418b', dark: '#2D5BA5' },
  { light: '#6435c9', dark: '#7E4FE3' },
  { light: '#972878', dark: '#972878' },
  { light: '#e03997', dark: '#FA53B1' },
  { light: '#dd324f', dark: '#F74C69' },
  { light: '#a5673f', dark: '#a5673f' },
  { light: '#767676', dark: '#909090' },
  { light: '#1b1c1d', dark: '#353637' },
];

export interface InvoiceListProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match<{ status?: 'Paid' | 'Unpaid' | 'Overdue' | 'Archived' | 'Draft' }>;
}

export const dueBy = (date: Date) => {
  const diff = differenceInCalendarDays(date, new Date());
  if (diff == 0) {
    return 'Due today';
  }
  if (diff < 0) {
    return `Due ${diff * -1} days ago`;
  }
  return `Due in ${diff} days`;
};

const StatHeading = styled.h4`
  color: #555;
  margin: 0;
`;

const GraphTab = styled(Tab)`
  &&&& {
    .item.active {
      background-color: #f8f8f8;
    }
  }
`;

export const InvoiceList = forwardRef<HTMLDivElement, InvoiceListProps>(({ match, children, className, ...props }, ref) => {
  const { user } = useContext(UserContext);
  const { invoices, outstanding, loading, hydrate, total: totalInvoices } = useContext(InvoiceContext);
  const { customers } = useContext(CustomerContext);

  const [filter, setFilter] = useQueryParams({
    search: StringParam,
    customer: StringParam,
    status: StringParam,
    page: NumberParam,
    pageSize: NumberParam,
  });

  const filterBy = useDebounce(filter, 300);
  const customer = customers.find((customer) => customer.id == filter.customer) || { id: '', name: '' };

  useEffect(() => {
    if (user.company) {
      hydrate(
        user.company,
        { size: filterBy.pageSize || 50, number: (filterBy.page || 1) - 1 },
        {
          status: filterBy.status || 'All',
          search: filterBy.search,
          customer: filterBy.customer,
        },
      );
    }
  }, [filterBy, user]);

  const sortCurrencies = (a: string, b: string) => {
    if (currencies[a].country_codes.includes(user.companies[0].country)) {
      return -1;
    }
    if (currencies[b].country_codes.includes(user.companies[0].country)) {
      return 1;
    }
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  };

  const chartCurrencies = useMemo(
    () =>
      Object.keys(
        outstanding.outstanding.reduce((currencies: any, month) => {
          return Object.keys(month).reduce((currencies, key) => {
            if (key.includes('Open') || key.includes('Paid')) {
              const [currency] = key.split(' ');
              currencies[currency] = true;
            }
            return currencies;
          }, currencies);
        }, {}),
      ).sort(sortCurrencies),
    [outstanding, currencies],
  );

  if (loading.error) {
    return (
      <Notice icon={<Icon name="exclamation circle" color="olive" />} message="Something went wrong fetching your invoices" color="olive">
        <Button icon labelPosition="right" color="olive" onClick={() => hydrate(user.company)}>
          <Icon name="refresh" />
          Try again
        </Button>
      </Notice>
    );
  }

  if (!loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Loading your invoices...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <TopSection>
        <h3>Invoices {loading.loading && <Loader size="tiny" inline active />}</h3>
        <Button icon labelPosition="right" color="olive" as={Link} to="/invoices/create">
          <Icon name="add circle" />
          Add a new invoice
        </Button>
      </TopSection>
      {chartCurrencies.length > 0 && (
        <GraphTab
          panes={chartCurrencies.map((currency: string) => {
            return {
              menuItem: currencies[currency].name,
              render: () => {
                return (
                  <Tab.Pane>
                    <GraphGrid>
                      <Grid.Row>
                        <Grid.Column width="5" stretched>
                          <StatSegment color="olive">
                            <StatHeading>Open</StatHeading>
                            <Statistic.Group horizontal size="small">
                              <Statistic
                                key={currency}
                                value={`${currencies[currency].symbol} ${outstanding.outstanding
                                  .reduce((total, item) => total.plus(((item as any)[`${currency} Open`] as any) || 0), new BigNumber(0))
                                  .toFormat(2)}`}
                              />
                            </Statistic.Group>
                          </StatSegment>
                          <StatSegment color="olive">
                            <StatHeading>Paid</StatHeading>
                            <Statistic.Group horizontal size="small">
                              <Statistic
                                key={currency}
                                value={`${currencies[currency].symbol} ${outstanding.outstanding
                                  .reduce((total, item) => total.plus(((item as any)[`${currency} Paid`] as any) || 0), new BigNumber(0))
                                  .toFormat(2)}`}
                              />
                            </Statistic.Group>
                          </StatSegment>
                        </Grid.Column>
                        <Grid.Column width="11">
                          <Segment color="olive">
                            <StatHeading>
                              Invoices issued {parse(outstanding.outstanding[0].month, 'yyyy-MM-dd', new Date()).toLocaleDateString()} -{' '}
                              {parse(
                                outstanding.outstanding[outstanding.outstanding.length - 1].month,
                                'yyyy-MM-dd',
                                new Date(),
                              ).toLocaleDateString()}
                            </StatHeading>
                            <br />
                            <ResponsiveContainer minHeight={250}>
                              <ComposedChart data={outstanding.outstanding} margin={{ left: 25 }}>
                                <Legend />
                                <CartesianGrid vertical={false} />
                                <YAxis type="number" tickFormatter={(value) => new BigNumber(value).toFormat(2)} />
                                <Tooltip formatter={(value: any) => new BigNumber(value).toFormat(2)} />
                                <XAxis
                                  type="category"
                                  tickCount={12}
                                  interval={0}
                                  dataKey="month"
                                  tickFormatter={(value) => format(parse(value, 'yyyy-MM-dd', new Date()), 'MMM')}
                                />
                                <Bar
                                  maxBarSize={80}
                                  stackId={`${currency}`}
                                  key={`${currency} Open`}
                                  fill={barColours[0].dark}
                                  dataKey={`${currency} Open`}
                                />
                                <Bar
                                  maxBarSize={80}
                                  stackId={`${currency}`}
                                  key={`${currency} Paid`}
                                  fill={barColours[0].light}
                                  dataKey={`${currency} Paid`}
                                />
                              </ComposedChart>
                            </ResponsiveContainer>
                          </Segment>
                        </Grid.Column>
                      </Grid.Row>
                    </GraphGrid>
                  </Tab.Pane>
                );
              },
            };
          })}
        />
      )}
      <Filters>
        <Menu>
          <Menu.Item color="olive" active={!filter.status || filter.status == 'All'} onClick={() => setFilter({ status: 'All' })}>
            All
          </Menu.Item>
          <Menu.Item color="orange" active={filter.status == 'Sent'} onClick={() => setFilter({ status: 'Sent' })}>
            Sent
          </Menu.Item>
          <Menu.Item color="orange" active={filter.status == 'Draft'} onClick={() => setFilter({ status: 'Draft' })}>
            Draft
          </Menu.Item>
          <Menu.Item color="orange" active={filter.status == 'Unpaid'} onClick={() => setFilter({ status: 'Unpaid' })}>
            Unpaid
          </Menu.Item>
          <Menu.Item color="green" active={filter.status == 'Paid'} onClick={() => setFilter({ status: 'Paid' })}>
            Paid
          </Menu.Item>
          <Menu.Item color="green" active={filter.status == 'Archived'} onClick={() => setFilter({ status: 'Archived' })}>
            Archived
          </Menu.Item>
          <Menu.Item color="pink" active={filter.status == 'Overdue'} onClick={() => setFilter({ status: 'Overdue' })}>
            Overdue
          </Menu.Item>
        </Menu>
        <Select
          placeholder="Customer"
          value={{ label: customer.name || 'Select a customer', value: customer.id }}
          styles={{ container: (base) => ({ ...base, width: 350 }) }}
          onChange={(value: any) => setFilter({ customer: value.value })}
          options={[{ value: '', label: '' }, ...customers.map((customer) => ({ value: customer.id, label: customer.name }))]}
        />
        <Input
          style={{ flex: 1 }}
          Two
          icon="search"
          value={filter.search}
          onChange={(e) => setFilter({ search: e.target.value })}
          placeholder="Start typing to find an invoice"
        />
      </Filters>
      <PadlessSegment color="olive">
        <Table fixed sortable celled>
          <T.Header>
            <T.Row>
              <T.HeaderCell width="two">Status</T.HeaderCell>
              <T.HeaderCell width="three">Actions</T.HeaderCell>
              <T.HeaderCell width="two">Number</T.HeaderCell>
              <T.HeaderCell style={{ width: '120px' }}>Due By</T.HeaderCell>
              <T.HeaderCell width="two">Issue Date</T.HeaderCell>
              <T.HeaderCell width="eight">Subject</T.HeaderCell>
              <T.HeaderCell textAlign="right" width="two">
                Value
              </T.HeaderCell>
            </T.Row>
          </T.Header>
          <T.Body>
            {invoices.length <= 0 && (
              <TableRow>
                <TableCell colSpan={9}>There are no invoices for the selected criteria</TableCell>
              </TableRow>
            )}
            {invoices.map((invoice) => (
              <T.Row key={invoice.id}>
                <T.Cell>
                  <Status status={invoice.status} />
                </T.Cell>
                <T.Cell>
                  <Actions invoice={invoice} />
                </T.Cell>
                <T.Cell>
                  <a href={`/invoices/${invoice.id}/edit/preview`}>{invoice.number}</a>
                </T.Cell>
                <T.Cell>{(invoice.status == 'Overdue' || invoice.status == 'Sent') && dueBy(invoice.due as Date)}</T.Cell>
                <T.Cell>{(invoice.issued as Date).toLocaleDateString()}</T.Cell>
                <T.Cell>
                  <span className="invoice-list__customer-item">{invoice.customerDisplayName}</span>
                  <span className="invoice-list__subject-item">{invoice.customerReference}</span>
                </T.Cell>
                <T.Cell textAlign="right">
                  {currencies[invoice.currency].symbol} {new BigNumber(invoice.total || 0).toFormat(2)}
                </T.Cell>
              </T.Row>
            ))}
          </T.Body>
          <T.Footer>
            <T.Row>
              <T.HeaderCell colSpan={4}></T.HeaderCell>
              <T.HeaderCell textAlign="right">
                <b>Total</b>
              </T.HeaderCell>
              <T.HeaderCell textAlign="right">
                {invoices.length > 0 ? (
                  <>
                    {Object.entries(
                      invoices.reduce((totals: { [index: string]: BigNumber }, invoice) => {
                        if (!totals[invoice.currency]) {
                          totals[invoice.currency] = new BigNumber(0);
                        }
                        totals[invoice.currency] = totals[invoice.currency].plus(invoice.total || 0);
                        return totals;
                      }, {}),
                    ).map(([currency, value]) => {
                      return (
                        <React.Fragment key={currency}>
                          {currencies[currency].symbol} {value.toFormat(2)} <br />
                        </React.Fragment>
                      );
                    })}
                  </>
                ) : (
                  'n/a'
                )}
              </T.HeaderCell>
            </T.Row>
          </T.Footer>
        </Table>{' '}
        {loading.loading && <Loader active={loading.loading} />}
      </PadlessSegment>
      <Pager
        page={{
          size: filterBy.pageSize || 50,
          number: filterBy.page || 1,
          total: totalInvoices,
        }}
        onChange={({ size, number }) => {
          setFilter({
            pageSize: size,
            page: number,
          });
        }}
      />
    </div>
  );
});

type ActionsProps = {
  invoice: Invoice;
};

const Actions: FC<ActionsProps> = ({ invoice }) => {
  const { user } = useContext(UserContext);
  const { customers } = useContext(CustomerContext);
  const { hydrate } = useContext(InvoiceContext);

  const customer = customers.find((customer) => (invoice || { customer: '' }).customer == customer.id) || {
    id: '',
    customerEmail: '',
    name: '',
  };

  return (
    <FinalForm
      initialValues={{
        ...invoice,
        mail: false,
        due: format(invoice.due as Date, 'yyyy-MM-dd'),
        issued: format(invoice.issued as Date, 'yyyy-MM-dd'),
        recurs: { value: invoice.recurs, label: invoice.recurs },
        mailTo: [{ value: customer.customerEmail, label: customer.customerEmail }],
        datePaid: invoice.datePaid ? format(invoice.datePaid as Date, 'yyyy-MM-dd') : format(new Date(), 'yyyy-MM-dd'),
        items: (invoice.items || []).reduce((items: { [id: string]: any }, item) => {
          items[item.id] = item;
          return items;
        }, {}),
        customer: {
          value: invoice.customer,
          label: customer.name,
        },
      }}
      onSubmit={async (v) => {
        const values = v as any;
        try {
          const [response, _] = await updateInvoice(user.company, values.id, {
            ...values,
            status: values.newStatus || values.status,
            datePaid: values.datePaid,
            issued: values.issued,
            mailTo: values.mailTo.map((v: any) => v.value || ''),
            customer: values.customer.value,
            recurs: values.recurs.value,
          });
          if (response.status == 422) {
            const validation = await response.json();
            return extractValidationError(validation);
          }
          if (response.status != 200) {
            throw new Error('Unable to save');
          }
          await hydrate(user.company);
        } catch (e) {
          console.log('submitting error', e);
          return {
            message: 'Failed to save this invoice',
          };
        }
      }}
      render={({ values }) => (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>
          {values.status != 'Paid' && <MarkPaidAction />}
          <ResendInvoiceAction />
          <DuplicateInvoiceAction />
        </div>
      )}
    />
  );
};

const DuplicateInvoiceAction = ({}) => {
  const { hydrate } = useContext(InvoiceContext);
  const { user } = useContext(UserContext);

  const { values: invoiceValue, submitting } = useFormState();

  return (
    <Button
      icon
      type="button"
      disabled={submitting}
      loading={submitting}
      onClick={async () => {
        try {
          const values = await getInvoice(user.company, invoiceValue.id);
          const [_, result] = await addInvoice(user.company, {
            ...values,
            number: undefined,
            customer: values.customer,
            vatRate: values.vatRate,
            recurs: values.recurs,
            items: Object.values(values.items) as InvoiceItem[],
            due: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
            issued: format(new Date(), 'yyyy-MM-dd'),
            mailTo: [],
            status: 'Draft',
            sent: undefined,
          });
          await hydrate(user.company, { number: 0, size: 50 });
          return history.push(`/invoices/${result.data.id}/edit`);
        } catch (error) {
          console.log('error', error);
        }
      }}
    >
      <Icon name="copy" />
    </Button>
  );
};

type MarkPaidActionProps = {};

const MarkPaidAction: FC<MarkPaidActionProps> = ({}) => {
  const form = useForm();
  const { values, submitting } = useFormState();

  const [open, setOpen] = useState(false);

  return (
    <Popup
      trigger={
        <Button type="button" loading={submitting} icon size="tiny">
          <Icon name="credit card" />
        </Button>
      }
      position="bottom center"
      on="click"
      hoverable={false}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <label style={{ fontWeight: 'bold' }}>Mark the Date Paid</label>
      <Field
        disabled={submitting}
        name="datePaid"
        validate={(v: any) => {
          if (!v) return 'Please select the date the invoice was paid';
          if (Date.parse(v) < Date.parse(values.issued)) return 'Select a date after the date issued';
        }}
        control={Input}
        type="date"
        component={FormField}
      />
      <Button
        fluid
        type="button"
        onClick={async (e) => {
          e.preventDefault();
          form.change('newStatus', 'Paid');
          await form.submit();
          setOpen(false);
        }}
        loading={submitting}
        disabled={submitting}
        color={invoiceColors['Draft']}
      >
        <Icon name="credit card" />
        Mark Paid
      </Button>
    </Popup>
  );
};

type ResendInvoiceActionProps = {};

const ResendInvoiceAction: FC<ResendInvoiceActionProps> = ({}) => {
  const form = useForm();
  const { values, submitting } = useFormState();

  const [open, setOpen] = useState(false);

  return (
    <Popup
      wide
      on="click"
      open={open}
      hoverable={false}
      position="bottom center"
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button icon type="button" size="tiny" disabled={submitting} loading={submitting}>
          <Icon name="mail" />
        </Button>
      }
    >
      <SendConfirmation>
        <SendConfirmationMessage>
          <Field
            isMulti
            disabled={submitting}
            name="mailTo"
            component={FormField}
            style={{ width: '100%' }}
            label="Send this invoice to"
            control={CreateableDropdown}
            validate={(value: any) => (value || []).length < 1 && 'You need to select a person to mail to'}
            options={[]}
          />
        </SendConfirmationMessage>
        <Button.Group size="tiny" floated="right">
          <Button type="button" disabled={submitting} onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            color={'black'}
            disabled={submitting}
            loading={submitting}
            onClick={async (e) => {
              e.preventDefault();
              form.batch(() => {
                form.change('mail', true);
                if (values.status == 'Draft') {
                  return form.change('newStatus', 'Sent');
                }
              });
              await form.submit();
              setOpen(false);
            }}
          >
            Confirm
          </Button>
        </Button.Group>
      </SendConfirmation>
    </Popup>
  );
};
