import React, { createContext, useState, useMemo, FC } from 'react';
import { User, whoami } from '../lib/api/user';
import { Company } from '../lib/api/companies';

const defaultUser = {
  id: '',
  email: '',
  displayName: '',
  firstName: '',
  lastName: '',
  language: '',
  company: '',
  companyName: '',
  contactNumber: '',
  companies: [],
  imageVersion: '',
  companyDetails: {},
  roles: [],
};

type Loading = {
  loading: boolean;
  loaded: boolean;
  error?: Error | unknown;
  updated_at?: number;
};

export type ContextUser = User & { companyDetails: Partial<Company> };

export type UserContextState = {
  user: ContextUser;
  loading: Loading;
  hydrate: () => Promise<void>;
};

export const UserContext = createContext<UserContextState>({
  user: defaultUser,
  loading: { loading: false, loaded: false, error: undefined, updated_at: undefined },
  hydrate: () => Promise.resolve(),
});

export const UserProvider: FC = ({ children }) => {
  const [user, setUser] = useState<ContextUser>(defaultUser);
  const [loading, setLoading] = useState<Loading>({ loading: false, loaded: false, error: undefined, updated_at: undefined });
  const value = useMemo(
    () => ({
      user,
      loading,
      hydrate: async () => {
        try {
          setLoading({
            loading: true,
            loaded: false,
            error: undefined,
          });
          const user = await whoami();
          console.log('User returned is: ', user);
          setUser({
            ...user,
            companyDetails: user.companies.find((c) => c.id == user.company) || {},
          });
          setLoading({
            loading: false,
            loaded: true,
            error: undefined,
          });
        } catch (e) {
          console.log('Hydration error: ', e);
          setLoading({
            loading: false,
            loaded: false,
            error: e,
          });
        }
      },
      setUser,
    }),
    [user, loading],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
