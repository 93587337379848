import React from 'react';
import { Router, Switch, Route } from 'react-router';
import { history } from './lib/history';
import Layout from './layout';
import { RegisterInterest } from './pages/register-interest';
import { Login } from './pages/login';
import { UserProvider } from './contexts/UserContext';
import { EnsureUser } from './fetchers/EnsureUser';
import { Profile } from './pages/profile';
import { CompanyProvider } from './contexts/CompanyContext';
import { EnsureCompany } from './fetchers/EnsureCompany';
import { EnsureCustomers } from './fetchers/EnsureCustomers';
import { CustomerProvider } from './contexts/CustomerContext';
import { Customers } from './pages/profile/customers';
import { Statements } from './pages/profile/statements';
import { LookupContext, LookupProvider } from './contexts/LookupsContext';
import { EnsureLookups } from './fetchers/EnsureLookups';
import { CustomerPeopleProvider } from './contexts/CustomerPeopleContext';
import { Dashboard } from './pages/dashboard';
import { Invoices } from './pages/invoices';
import { Reports } from './pages/reports';
import { InvoiceProvider } from './contexts/InvoiceContext';
import { EnsureInvoices } from './fetchers/EnsureInvoices';
import { BillingProvider } from './contexts/BillingContext';
import { EnsureBilling } from './fetchers/Billing';
import { Scratch } from './pages/scratch';
import { QueryParamProvider } from 'use-query-params';
import { Admin } from './pages/admin';
import { TrailGaurd } from './TrialGaurd';
import { Settings } from './pages/settings';

const App: React.FC = () => {
  return (
    <Router history={history}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <UserProvider>
          <Switch>
            <Route path="/password-reset/:token" component={Login} />
            <Route path="/sign-up" component={Login} />
            <Route path="/forgot-password" component={Login} />
            <Route path="/login" component={Login} />
            <Route
              path="/admin"
              render={() => (
                <>
                  <Route component={EnsureUser} />
                  <Admin />
                </>
              )}
            />
            <Route
              path="/"
              render={() => (
                <>
                  <Route component={EnsureUser} />
                  <LookupProvider>
                    <CompanyProvider>
                      <CustomerProvider>
                        <CustomerPeopleProvider>
                          <InvoiceProvider>
                            <BillingProvider>
                              <Layout>
                                <Route component={EnsureCompany} />
                                <Route component={EnsureCustomers} />
                                <Route component={EnsureLookups} />
                                <Route component={EnsureInvoices} />
                                <Route component={EnsureBilling} />
                                <TrailGaurd>
                                  <Switch>
                                    <Route path="/customers" component={Customers} />
                                    <Route path="/invoices" component={Invoices} />
                                    <Route path="/statements" component={Statements} />
                                    <Route path="/scratch" component={Scratch} />
                                    <Route path="/reports" component={Reports} />
                                    <Route path="/profile" component={Profile} />
                                    <Route path="/settings" component={Settings} />
                                    <Route component={Invoices} />
                                  </Switch>
                                </TrailGaurd>
                              </Layout>
                            </BillingProvider>
                          </InvoiceProvider>
                        </CustomerPeopleProvider>
                      </CustomerProvider>
                    </CompanyProvider>
                  </LookupProvider>
                </>
              )}
            />
          </Switch>
        </UserProvider>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
