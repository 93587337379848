import React, { forwardRef, useContext, HTMLAttributes } from 'react';
import classnames from 'classnames';
import { Segment, Form, Grid, Input, Button, Loader } from 'semantic-ui-react';
import { extractValidationError } from '../../../lib/utils';
import { Field, Form as FinalForm } from 'react-final-form';
import { FormField } from '../../../shared/Fields';
import { profileColors } from '../../profile/shared';
import { UserContext } from '../../../contexts/UserContext';
import { LoadingWrapper } from '../../../shared/Loading';
import { resetPasswrod as resetPassword } from '../../../lib/api/user';
import { validatePassword } from '../../../lib/validation';

export interface PasswordResetProps extends HTMLAttributes<HTMLDivElement> {}

export const PasswordReset = forwardRef<HTMLDivElement, PasswordResetProps>(({ children, className, ...props }, ref) => {
  const { loading, hydrate } = useContext(UserContext);

  if (!loading.loaded) {
    return (
      <LoadingWrapper {...props} ref={ref} className={classnames('', {}, className)}>
        <Loader size="medium" active inline>
          Fetching...
        </Loader>
      </LoadingWrapper>
    );
  }

  return (
    <div ref={ref} className={classnames('', {}, className)} color={profileColors.profile}>
      <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <h3>Password</h3>
                <FinalForm<{ password: string; confirmPassword: string }>
                  onSubmit={async (values) => {
                    const response = await resetPassword(values);
                    if (response.status == 422) {
                      const validation = await response.json();
                      return extractValidationError(validation);
                    }
                    if (response.status != 200) {
                      throw new Error('Unable to save');
                    }
                    await hydrate();
                  }}
                  render={({ handleSubmit, submitting, form, dirty }) => {
                    return (
                      <Form onSubmit={handleSubmit}>
                        <Segment color={profileColors.profile}>
                          <Grid>
                            <Grid.Row>
                              <Grid.Column width={15}>
                                <Field
                                  name="password"
                                  type="password"
                                  validate={validatePassword}
                                  label=" Password"
                                  placeholder="New Password"
                                  control={Input}
                                  component={FormField}
                                />
                                <Field
                                  name="confirmPassword"
                                  type="password"
                                  validate={(value, values: any = {}) => {
                                    if (value != values.password) {
                                      return 'Must match password';
                                    }
                                    return validatePassword(value);
                                  }}
                                  label="Confirm Password"
                                  placeholder="Password"
                                  control={Input}
                                  component={FormField}
                                />
                              </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                              <Grid.Column width="15">
                                <Button.Group>
                                  <Button type="submit" loading={submitting} color={profileColors.profile}>
                                    Reset
                                  </Button>
                                  <Button disabled={!dirty} onClick={() => form.reset()}>
                                    Clear
                                  </Button>
                                </Button.Group>
                              </Grid.Column>
                            </Grid.Row>
                          </Grid>
                        </Segment>
                      </Form>
                    );
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
    </div>
  );
});
