import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { Switch, Route, match } from 'react-router';
import { PasswordReset } from './password-reset';

export interface SettingsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  match: match;
}

export const Settings = forwardRef<HTMLDivElement, SettingsProps>(({ children, className, ...props }, ref) => {
  return (
    <div ref={ref} className={classnames('', {}, className)}>
      <Switch>
        <Route path="/settings/password/reset" component={PasswordReset} />
        <Route component={PasswordReset} />
      </Switch>
    </div>
  );
});
