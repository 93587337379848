import React, { forwardRef, useContext, useState, ReactText } from 'react';
import BigNumber from 'bignumber.js';
import classnames from 'classnames';
import { PadlessSegment, Segment } from '../../../shared/Segments';
import { Table, Button, Icon } from 'semantic-ui-react';
import { currencies } from '../../../lib/currencies';
import { ContextUser } from '../../../contexts/UserContext';

export interface StatementProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  user: ContextUser;
  filter: {
    customer: string | undefined;
    startMonth: string | undefined;
    endMonth: string | undefined;
  };
}

export const Statement = forwardRef<HTMLDivElement, StatementProps>(({ children, className, user, filter, ...props }, ref) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          as="a"
          href={`/api/companies/${user.company}/statement/pdf?customer=${filter.customer}&startMonth=${filter.startMonth}&endMonth=${filter.endMonth}`}
          download
          color="green"
        >
          <Icon name="download" />
          Download
        </Button>
      </div>
      <PadlessSegment>
        <Segment>
          <iframe
            style={{ border: 'none', width: '100%', minHeight: '80vh' }}
            src={`/api/companies/${user.company}/statement?customer=${filter.customer}&startMonth=${filter.startMonth}&endMonth=${filter.endMonth}`}
          />
        </Segment>
      </PadlessSegment>
    </>
  )
})