import queryString from 'query-string';

export const apiHeaders = (content: { length: number } = { length: 0 }, options: {} = {}) =>
  new Headers({
    Accept: 'application/json',
    'Content-Length': `${content.length}`,
    'Content-Type': 'application/json',
    'X-csrf-validation': '-',
    'Access-Control-Allow-Credentials': 'true',
    ...options,
  });

export class ApiError extends Error {
  isApiError = true;
  response: Response;

  constructor(message: string, response: Response) {
    super(message);
    this.response = response;
  }
}

export const request = async (path: string, options: RequestInit = { credentials: 'same-origin' }) => {
  const response = await fetch(`https://api.vulcaninvoicing.com/api${path}`, {
    headers: apiHeaders(typeof options.body == 'string' ? options.body : undefined),
    ...options,
  });
  if (response.status > 500 && response.status < 600) {
    const { message } = await response.json();
    throw new Error(message);
  }
  return response;
};

export const formDataSize = (form: FormData) => {
  let size = 0;
  for (const pair of form.entries()) {
    if (pair[1] instanceof Blob) size += pair[1].size;
    else size += pair[1].length;
  }
  return size;
};

export type Query = {
  page: {
    number: number;
    size: number;
  };
  include: string[];
  sort: { [column: string]: 'asc' | 'desc' };
  where?: any;
};

export const all = async <T>(endpoint: string, query: Query): Promise<{ data: T[]; meta: { total: number } }> => {
  const response = await request(
    `/${endpoint}?page[number]=${query.page.number}&page[size]=${query.page.size}&${Object.entries(query.where)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `where[${key}][]=${v}`).join('&');
        }
        return !!value ? `where[${key}]=${value}` : ``;
      })
      .join('&')}&${query.include.map((include) => `include[]=${include}`).join('&')}&${Object.entries(query.sort)
      .map(([key, value]) => {
        return !!value ? `sort[${key}]=${value}` : ``;
      })
      .join('&')}`,
    {},
  );

  if (response.status == 401) {
    throw new ApiError(`You are not authenticated`, response);
  }

  if (response.status > 299 || response.status < 200) {
    throw new ApiError(`Unexpected error whilst trying to fetch ${endpoint}`, response);
  }
  const records = await response.json();
  return records;
};

export const single = async <T>(endpoint: string, id: string, include: string[]): Promise<T> => {
  const response = await request(`/${endpoint}/${id}?${queryString.stringify({ include })}`, {});

  if (response.status == 401) {
    throw new ApiError(`You are not authenticated`, response);
  }

  if (response.status > 299 || response.status < 200) {
    throw new ApiError(`Unexpected error whilst trying to fetch ${endpoint}`, response);
  }
  const record = await response.json();
  return record;
};

export const create = async <T>(endpoint: string, record: Partial<T>, include: string[]): Promise<T> => {
  const response = await request(`/${endpoint}?${queryString.stringify({ include })}`, {
    method: 'POST',
    body: JSON.stringify({ data: record }),
  });

  if (response.status == 401) {
    throw new ApiError(`You are not authenticated`, response);
  }

  if (response.status > 299 || response.status < 200) {
    throw new ApiError(`Unexpected error whilst trying to fetch ${endpoint}`, response);
  }

  const result = await response.json();
  return result;
};

export const update = async <T>(endpoint: string, id: string, record: Partial<T>, include: string[]): Promise<T> => {
  const response = await request(`/${endpoint}/${id}?${queryString.stringify({ include })}`, {
    method: 'PUT',
    body: JSON.stringify({ data: record }),
  });

  if (response.status == 401) {
    throw new ApiError(`You are not authenticated`, response);
  }

  if (response.status > 299 || response.status < 200) {
    throw new ApiError(`Unexpected error whilst trying to fetch ${endpoint}`, response);
  }

  const result = await response.json();
  return result;
};

export type Endpoint<T> = {
  all: (query: Query) => Promise<{ data: T[]; meta: { total: number } }>;
  single: (id: string, include: string[]) => Promise<T>;
  create: (resource: Partial<T>, include: string[]) => Promise<T>;
  update: (id: string, resource: Partial<T>, include: string[]) => Promise<T>;
};

export const endpoint = <T>({ endpoint }: { endpoint: string }): Endpoint<T> => {
  return {
    all: (query: Query) => all<T>(endpoint, query),
    single: (id: string, includes: string[]) => single<T>(endpoint, id, includes),
    create: (resource: Partial<T>, includes: string[]) => create<T>(endpoint, resource, includes),
    update: (id: string, resource: Partial<T>, includes: string[]) => update<T>(endpoint, id, resource, includes),
  };
};
